import {
  CaptionPosition,
  FontFamily,
  FontType,
  Rotation,
} from "../interfaces/application-state";
import { IColourManager } from "./ColourManager";
import { IDateManager } from "./DateManager";
import type { IImageReader } from "./ImageReader";

const galleryItemSize = 200;

export interface IImageRenderer {
  clear(canvas: HTMLCanvasElement, abortController: AbortController): void;
  render(
    file: File,
    canvas: HTMLCanvasElement,
    backColour: string,
    canvasHeight: number,
    canvasWidth: number,
    canvasPosition: CaptionPosition,
    dateTaken: string | undefined,
    font: FontFamily,
    fontSize: number,
    fontType: FontType,
    foreColour: string,
    includeDateTaken: boolean,
    opacity: number,
    rotation: Rotation,
    text: string,
    useCanvas: boolean,
    abortController: AbortController
  ): Promise<string>;
  createPreview(image: HTMLImageElement): Promise<string>;
}

const verticalPadding = 8;

export class ImageRenderer implements IImageRenderer {
  constructor(
    private colourManager: IColourManager,
    private dateManager: IDateManager,
    private imageReader: IImageReader
  ) {}

  private buildCaption(
    text: string,
    includeDateTaken: boolean,
    dateTaken: string | undefined
  ): string[] {
    let caption = text.trim();

    // do we need to append the date?
    if (includeDateTaken && dateTaken) {
      const dateTakenAsDate = new Date(dateTaken);

      caption += " - " + this.dateManager.getDate(dateTakenAsDate);
    }

    // split it into lines
    return caption.split("\n");
  }

  public clear(
    canvas: HTMLCanvasElement,
    abortController: AbortController
  ): void {
    // has the request been aborted?
    if (abortController.signal.aborted) {
      return;
    }

    // get the canvas context
    const context = canvas.getContext("2d");

    context?.clearRect(0, 0, canvas.width, canvas.height);
  }

  public createPreview(value: HTMLImageElement): Promise<string> {
    return new Promise<string>((resolve, reject): void => {
      requestIdleCallback((): void => {
        // create the canvas for the preview generation
        const canvas = document.createElement("canvas");
        canvas.height = galleryItemSize;
        canvas.width = galleryItemSize;

        // get the context
        const ctx = canvas.getContext("2d");
        if (!ctx) {
          reject(new Error("Unable to retrieve canvas context"));

          return;
        }

        // work out the dimensions for the preview
        const ratio = Math.min(
          galleryItemSize / value.width,
          galleryItemSize / value.height
        );
        const newWidth = value.width * ratio;
        const newHeight = value.height * ratio;
        const newX = (galleryItemSize - newWidth) / 2;
        const newY = (galleryItemSize - newHeight) / 2;

        // now render the preview
        ctx.imageSmoothingQuality = "low";
        ctx.drawImage(value, newX, newY, newWidth, newHeight);
        const dataUrl = canvas.toDataURL();

        resolve(dataUrl);
      });
    });
  }

  public render(
    file: File,
    canvas: HTMLCanvasElement,
    backColour: string,
    canvasHeight: number,
    canvasWidth: number,
    captionPosition: CaptionPosition,
    dateTaken: string | undefined,
    font: FontFamily,
    fontSize: number,
    fontType: FontType,
    foreColour: string,
    includeDateTaken: boolean,
    opacity: number,
    rotation: Rotation,
    text: string,
    useCanvas: boolean,
    abortController: AbortController
  ): Promise<string> {
    return new Promise((resolve, reject): void => {
      let offset: number;
      let x: number;

      // load the image
      this.imageReader
        .read(file, true)
        .then((value: HTMLImageElement): void => {
          let imageHeight, imageWidth;
          let ratio: number;

          if (abortController.signal.aborted) {
            reject(new Error("Operation has been cancelled"));

            return;
          }

          // get the canvas context
          const context = canvas.getContext("2d");

          if (context === null) {
            reject(new Error("Unable to retrieve canvas context"));

            return;
          }

          context.imageSmoothingEnabled = true;
          context.imageSmoothingQuality = "high";

          switch (rotation) {
            case Rotation.None:
              // set the canvas dimensions
              if (useCanvas) {
                canvas.height = canvasHeight;
                canvas.width = canvasWidth;
              } else {
                canvas.height = value.height;
                canvas.width = value.width;
              }

              // work out the dimensions of the image that fit within the canvas
              ratio = Math.min(
                canvas.height / value.height,
                canvas.width / value.width
              );
              imageHeight = value.height * ratio;
              imageWidth = value.width * ratio;

              // set the background colour
              context.save();
              context.fillStyle = "#000000";
              context.fillRect(0, 0, canvas.width, canvas.height);
              context.translate(canvas.width / 2, canvas.height / 2);
              context.drawImage(
                value,
                -imageWidth / 2,
                -imageHeight / 2,
                imageWidth,
                imageHeight
              );
              context.restore();

              break;
            case Rotation.Ninety:
              if (useCanvas) {
                canvas.height = canvasHeight;
                canvas.width = canvasWidth;
              } else {
                // set the canvas dimensions
                canvas.height = value.width;
                canvas.width = value.height;
              }

              // work out the dimensions of the image that fit within the canvas
              ratio = Math.min(
                canvas.height / value.width,
                canvas.width / value.height
              );
              imageHeight = value.height * ratio;
              imageWidth = value.width * ratio;

              // clear the canvas
              context?.save();
              context.fillStyle = "#000000";
              context?.fillRect(0, 0, canvas.width, canvas.height);
              context?.translate(canvas.width / 2, canvas.height / 2);
              context?.rotate((90 * Math.PI) / 180);
              context?.drawImage(
                value,
                -imageWidth / 2,
                -imageHeight / 2,
                imageWidth,
                imageHeight
              );
              context?.transform(1, 0, 0, 1, 0, 0);
              context?.restore();

              break;
            case Rotation.OneEighty:
              // set the canvas dimensions
              if (useCanvas) {
                canvas.height = canvasHeight;
                canvas.width = canvasWidth;
              } else {
                canvas.height = value.height;
                canvas.width = value.width;
              }

              // work out the dimensions of the image that fit within the canvas
              ratio = Math.min(
                canvas.height / value.height,
                canvas.width / value.width
              );
              imageHeight = value.height * ratio;
              imageWidth = value.width * ratio;

              // set the background colour
              context.save();
              context.fillStyle = "#000000";
              context.fillRect(0, 0, canvas.width, canvas.height);
              context.translate(canvas.width / 2, canvas.height / 2);
              context.rotate(Math.PI);
              context.drawImage(
                value,
                -imageWidth / 2,
                -imageHeight / 2,
                imageWidth,
                imageHeight
              );
              context.transform(1, 0, 0, 1, 0, 0);
              context.restore();

              break;
            default:
              if (useCanvas) {
                canvas.height = canvasHeight;
                canvas.width = canvasWidth;
              } else {
                // set the canvas dimensions
                canvas.height = value.width;
                canvas.width = value.height;
              }

              // work out the dimensions of the image that fit within the canvas
              ratio = Math.min(
                canvas.height / value.width,
                canvas.width / value.height
              );
              imageHeight = value.height * ratio;
              imageWidth = value.width * ratio;

              // clear the canvas
              context?.save();
              context.fillStyle = "#000000";
              context?.fillRect(0, 0, canvas.width, canvas.height);
              context?.translate(canvas.width / 2, canvas.height / 2);
              context?.rotate((270 * Math.PI) / 180);
              context?.drawImage(
                value,
                -imageWidth / 2,
                -imageHeight / 2,
                imageWidth,
                imageHeight
              );
              context?.transform(1, 0, 0, 1, 0, 0);
              context?.restore();

              break;
          }

          // work out the correct font size
          let fontSizeWithType = fontSize;
          if (fontType === "%") {
            fontSizeWithType = (canvas.height * fontSize) / 100;
          }

          // build the caption to be used
          const caption = this.buildCaption(text, includeDateTaken, dateTaken);

          // setup the text
          context.font = `${fontSizeWithType}px ${font}`;
          context.textAlign = "left";

          switch (captionPosition) {
            case "BottomLeft":
              offset = canvas.height;

              for (let i = caption.length - 1; i > -1; i--) {
                // get the line to be drawn
                const captionLine = caption[i];

                // Measure the width and height of the text
                const textMetrics = context.measureText(captionLine);
                const fillHeight =
                  textMetrics.actualBoundingBoxAscent +
                  textMetrics.actualBoundingBoxDescent +
                  2 * verticalPadding;
                const fillWidth = Math.min(textMetrics.width, canvas.width);

                // draw the background to the caption
                this.drawCaptionBackground(
                  context,
                  backColour,
                  opacity,
                  0,
                  offset - fillHeight,
                  fillWidth,
                  fillHeight
                );

                // now draw the caption
                this.drawCaption(
                  context,
                  0,
                  offset -
                    textMetrics.actualBoundingBoxDescent -
                    verticalPadding,
                  canvas.width,
                  foreColour,
                  captionLine
                );

                // moe up a line
                offset -=
                  textMetrics.actualBoundingBoxAscent +
                  textMetrics.actualBoundingBoxDescent +
                  2 * verticalPadding;
              }

              break;
            case "BottomMiddle":
              offset = canvas.height;

              for (let i = caption.length - 1; i > -1; i--) {
                // get the line to be drawn
                const captionLine = caption[i];

                // Measure the width and height of the text
                const textMetrics = context.measureText(captionLine);
                const fillHeight =
                  textMetrics.actualBoundingBoxAscent +
                  textMetrics.actualBoundingBoxDescent +
                  2 * verticalPadding;
                const fillWidth = Math.min(textMetrics.width, canvas.width);

                // work out the horizontal position
                x = (canvas.width - fillWidth) / 2;

                // draw the background to the caption
                this.drawCaptionBackground(
                  context,
                  backColour,
                  opacity,
                  x,
                  offset - fillHeight,
                  fillWidth,
                  fillHeight
                );

                // now draw the caption
                this.drawCaption(
                  context,
                  x,
                  offset -
                    textMetrics.actualBoundingBoxDescent -
                    verticalPadding,
                  canvas.width,
                  foreColour,
                  captionLine
                );

                // moe up a line
                offset -=
                  textMetrics.actualBoundingBoxAscent +
                  textMetrics.actualBoundingBoxDescent +
                  2 * verticalPadding;
              }

              break;
            case "BottomRight":
              offset = canvas.height;

              for (let i = caption.length - 1; i > -1; i--) {
                // get the line to be drawn
                const captionLine = caption[i];

                // Measure the width and height of the text
                const textMetrics = context.measureText(captionLine);
                const fillHeight =
                  textMetrics.actualBoundingBoxAscent +
                  textMetrics.actualBoundingBoxDescent +
                  2 * verticalPadding;
                const fillWidth = Math.min(textMetrics.width, canvas.width);

                // work out the horizontal position
                x = canvas.width - Math.min(canvas.width, fillWidth);

                // draw the background to the caption
                this.drawCaptionBackground(
                  context,
                  backColour,
                  opacity,
                  x,
                  offset - fillHeight,
                  fillWidth,
                  fillHeight
                );

                // now draw the caption
                this.drawCaption(
                  context,
                  x,
                  offset -
                    textMetrics.actualBoundingBoxDescent -
                    verticalPadding,
                  canvas.width,
                  foreColour,
                  captionLine
                );

                // moe up a line
                offset -=
                  textMetrics.actualBoundingBoxAscent +
                  textMetrics.actualBoundingBoxDescent +
                  2 * verticalPadding;
              }

              break;
            case "TopLeft":
              offset = 0;

              for (let i = 0; i < caption.length; i++) {
                // get the line to be drawn
                const captionLine = caption[i];

                // Measure the width and height of the text
                const textMetrics = context.measureText(captionLine);
                const fillHeight =
                  textMetrics.actualBoundingBoxAscent +
                  textMetrics.actualBoundingBoxDescent +
                  2 * verticalPadding;
                const fillWidth = Math.min(textMetrics.width, canvas.width);

                // draw the background to the caption
                this.drawCaptionBackground(
                  context,
                  backColour,
                  opacity,
                  0,
                  offset,
                  fillWidth,
                  fillHeight
                );

                // now draw the caption
                this.drawCaption(
                  context,
                  0,
                  offset +
                    textMetrics.actualBoundingBoxAscent +
                    verticalPadding,
                  canvas.width,
                  foreColour,
                  captionLine
                );

                // moe up a line
                offset +=
                  textMetrics.actualBoundingBoxAscent +
                  textMetrics.actualBoundingBoxDescent +
                  2 * verticalPadding;
              }

              break;
            case "TopMiddle":
              offset = 0;

              for (let i = 0; i < caption.length; i++) {
                // get the line to be drawn
                const captionLine = caption[i];

                // Measure the width and height of the text
                const textMetrics = context.measureText(captionLine);
                const fillHeight =
                  textMetrics.actualBoundingBoxAscent +
                  textMetrics.actualBoundingBoxDescent +
                  2 * verticalPadding;
                const fillWidth = Math.min(textMetrics.width, canvas.width);

                // work out the horizontal position
                x = (canvas.width - fillWidth) / 2;

                // draw the background to the caption
                this.drawCaptionBackground(
                  context,
                  backColour,
                  opacity,
                  x,
                  offset,
                  fillWidth,
                  fillHeight
                );

                // now draw the caption
                this.drawCaption(
                  context,
                  x,
                  offset +
                    textMetrics.actualBoundingBoxAscent +
                    verticalPadding,
                  canvas.width,
                  foreColour,
                  captionLine
                );

                // moe up a line
                offset +=
                  textMetrics.actualBoundingBoxAscent +
                  textMetrics.actualBoundingBoxDescent +
                  2 * verticalPadding;
              }

              break;
            case "TopRight":
              offset = 0;

              for (let i = 0; i < caption.length; i++) {
                // get the line to be drawn
                const captionLine = caption[i];

                // Measure the width and height of the text
                const textMetrics = context.measureText(captionLine);
                const fillHeight =
                  textMetrics.actualBoundingBoxAscent +
                  textMetrics.actualBoundingBoxDescent +
                  2 * verticalPadding;
                const fillWidth = Math.min(textMetrics.width, canvas.width);

                // work out the horizontal position
                x = canvas.width - fillWidth;

                // draw the background to the caption
                this.drawCaptionBackground(
                  context,
                  backColour,
                  opacity,
                  x,
                  offset,
                  fillWidth,
                  fillHeight
                );

                // now draw the caption
                this.drawCaption(
                  context,
                  x,
                  offset +
                    textMetrics.actualBoundingBoxAscent +
                    verticalPadding,
                  canvas.width,
                  foreColour,
                  captionLine
                );

                // moe up a line
                offset +=
                  textMetrics.actualBoundingBoxAscent +
                  textMetrics.actualBoundingBoxDescent +
                  2 * verticalPadding;
              }

              break;
          }

          // return the updated image
          resolve(canvas.toDataURL());
        });
    });
  }

  private drawCaption(
    context: CanvasRenderingContext2D,
    x: number,
    y: number,
    maxWidth: number,
    colour: string,
    caption: string
  ) {
    // now add the text
    context.save();
    context.fillStyle = colour;
    context.fillText(caption, x, y, maxWidth);
    context.restore();
  }

  private drawCaptionBackground(
    context: CanvasRenderingContext2D,
    colour: string,
    opacity: number,
    x: number,
    y: number,
    width: number,
    height: number
  ) {
    context.save();
    context.fillStyle = this.colourManager.hexToRGBA(colour, opacity / 100);
    context.fillRect(x, y, width, height);
    context.restore();
  }
}
