import ReactDOM from "react-dom/client";
import AppComponent from "./App";
import { HashRouter as Router } from "react-router-dom";
import { StrictMode } from "react";
import { Provider } from "react-redux";
import DependenciesProvider from "./DependencyProvider";
import store from "./Store";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <StrictMode>
    <Provider store={store}>
      <DependenciesProvider>
        <Router>
          <AppComponent />
        </Router>
      </DependenciesProvider>
    </Provider>
  </StrictMode>
);
