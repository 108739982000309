interface GetSignInUrlResponse {
  url: string;
}

export interface IAuthService {
  getSigninUrl(): Promise<string>;
}

export class AuthService implements IAuthService {
  private state = this.generateState();

  private generateState(): string {
    const chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < 32; i++) {
      const randomIndex = Math.floor(Math.random() * chars.length);
      result += chars[randomIndex];
    }
    return result;
  }

  async getSigninUrl(): Promise<string> {
    // build the request url
    var stateEncoded = encodeURI(this.state);
    const uri = `/api/Auth/GetSigninUrl?state=${stateEncoded}`;

    var response = await fetch(uri, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    // get the response
    let responseJson: GetSignInUrlResponse = await response.json();

    // return the url
    return responseJson.url;
  }
}
