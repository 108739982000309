import "./close.css";

import React, { useContext, useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { AppState } from "../../Store";
import { removeAllGalleryItems } from "../../reducers/itemsReducer";
import { DependencyContext } from "../../DependencyProvider";

const CloseComponent: React.FC = () => {
  const dispatch = useDispatch();

  // context
  const exifAsyncQueue = useContext(DependencyContext)!.exifAsyncQueue;
  const fileManager = useContext(DependencyContext)!.fileManager;
  const loadAsyncQueue = useContext(DependencyContext)!.loadAsyncQueue;

  // selector
  const selectedItem = useSelector(
    (state: AppState) => state.items.selectedItem
  );

  // state
  const [disabled, setDisabled] = useState(selectedItem === undefined);

  useEffect(() => {
    // Update the disabled state when props change
    setDisabled(selectedItem === undefined);
  }, [selectedItem]);

  const handleClick = (): void => {
    // remove all the items from the list
    dispatch(removeAllGalleryItems());

    // cancel any outstanding loads
    exifAsyncQueue.clear();
    loadAsyncQueue.clear();

    // clear the list of files
    fileManager.clear();
  };

  return (
    <button
      className="close"
      disabled={disabled}
      onClick={handleClick}
      title="Close All"
    >
      <img alt="Close All" className="close" src="images/close.png" />
    </button>
  );
};

export default CloseComponent;
