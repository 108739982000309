import React from "react";

import PhotoLabelComponent from "./components/photo-label/photo-label";

import "./App.css";
import { Navigate, Route, Routes } from "react-router-dom";
import Cookies from "universal-cookie";
import LoginComponent from "./components/login/login";

const AppComponent: React.FC = () => {
  const token = new Cookies().get("token");

  return (
    <Routes>
      <Route
        path="/"
        element={token ? <PhotoLabelComponent /> : <Navigate to="login" />}
      />
      <Route path="app" element={<PhotoLabelComponent />} />
      <Route path="login" element={<LoginComponent />} />
    </Routes>
  );
};

export default AppComponent;
