import React, { useEffect, useState } from "react";
import "./previous.css";
import { useSelector } from "react-redux";
import { AppState } from "../../Store";
import { useDispatch } from "react-redux";
import { previousGalleryItem } from "../../reducers/itemsReducer";

const PreviousComponent: React.FC = () => {
  const dispatch = useDispatch();

  // selectors
  const selectedIndex = useSelector(
    (state: AppState) => state.items.selectedIndex
  );

  // state
  const [disabled, setDisabled] = useState<boolean>(true);

  useEffect(() => {
    // Update the disabled state when props change
    setDisabled(selectedIndex < 1);
  }, [selectedIndex]);

  const handleClick = (): void => {
    // move to the previous item
    dispatch(previousGalleryItem());
  };

  return (
    <button
      className="previous"
      disabled={disabled}
      onClick={handleClick}
      title="Previous"
    >
      <img alt="previous" src="images/previous.png" />
    </button>
  );
};

export default PreviousComponent;
