import { createContext } from "react";
import { IImageRenderer, ImageRenderer } from "./services/ImageRenderer";
import { Cache } from "./services/Cache";
import { IImageReader, ImageReader } from "./services/ImageReader";
import { ColourManager } from "./services/ColourManager";
import { AsyncQueue, IAsyncQueue } from "./services/AsyncQueue";
import { ExifManager, IExifManager } from "./services/ExifManager";
import { ISettings, Settings } from "./services/Settings";
import { FileManager, IFileManager } from "./services/FileManager";
import { IPersistManager, PersistManager } from "./services/PersistManager";
import { DateManager, IDateManager } from "./services/DateManager";
import { AuthService, IAuthService } from "./services/AuthService";
import { CookieManager } from "./services/CookieManager";

export interface IDependencies {
  authService: IAuthService;
  dateManager: IDateManager;
  exifAsyncQueue: IAsyncQueue;
  exifManager: IExifManager;
  fileManager: IFileManager;
  imageReader: IImageReader;
  imageRenderer: IImageRenderer;
  loadAsyncQueue: IAsyncQueue;
  persistManager: IPersistManager;
  settings: ISettings;
}

// create the context for the dependencies
export const DependencyContext = createContext<IDependencies | undefined>(
  undefined
);

// create the dependencies
const authService = new AuthService();
const cookieManager = new CookieManager();
const dateManager = new DateManager();
const exifAsyncQueue = new AsyncQueue();
const loadAsyncQueue = new AsyncQueue(2);
const colourManager = new ColourManager();
const cache = new Cache<File, HTMLImageElement>();
const exifManager = new ExifManager();
const fileManager = new FileManager();
const imageReader = new ImageReader(cache);
const imageRenderer = new ImageRenderer(
  colourManager,
  dateManager,
  imageReader
);
const persistManager = new PersistManager(cookieManager, fileManager);
const settings = new Settings();

// Provider component for all of the dependencies
const DependenciesProvider: React.FC<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  const dependency: IDependencies = {
    authService,
    dateManager,
    exifAsyncQueue,
    exifManager,
    fileManager,
    imageReader,
    imageRenderer,
    loadAsyncQueue,
    persistManager,
    settings,
  };

  return (
    <DependencyContext.Provider value={dependency}>
      {children}
    </DependencyContext.Provider>
  );
};

export default DependenciesProvider;
