import { GalleryItem } from "../interfaces/gallery-item";
import { IFileManager } from "./FileManager";
import { ICookieManager } from "./CookieManager";

export interface IPersistManager {
  get(file: File): Promise<GalleryItem | undefined>;
  save(item: GalleryItem): Promise<boolean>;
}

export class PersistManager implements IPersistManager {
  constructor(
    private readonly cookieManager: ICookieManager,
    private readonly fileManager: IFileManager
  ) {}

  async get(file: File): Promise<GalleryItem | undefined> {
    // get the token
    let token = this.cookieManager.get("token");

    // if there is no token, don;t do anything
    if (!token) {
      return undefined;
    }

    const params = {
      filename: file.name,
      size: String(file.size),
      lastModified: String(file.lastModified),
    };

    const url = new URL("/api/GalleryItem", window.location.origin);
    url.search = new URLSearchParams(params).toString();

    var result = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json", // Sets the content type to JSON
      },
    });

    if (!result.ok) {
      return undefined;
    }

    // get the response
    var response = (await result.json()) as GalleryItem;

    return response;
  }

  async save(item: GalleryItem): Promise<boolean> {
    // get the file details associated with this gallery item
    var file = this.fileManager.get(item.id);

    // if the file wasn't found, we can't save it
    if (!file) {
      return false;
    }

    // get the token
    let token = this.cookieManager.get("token");

    // if there is no token, don;t do anything
    if (!token) {
      return false;
    }

    var result = await fetch("/api/GalleryItem", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json", // Sets the content type to JSON
      },
      body: JSON.stringify({
        item,
        filename: file.name,
        size: file.size,
        lastModified: file.lastModified,
      }),
    });

    return result.ok;
  }
}
