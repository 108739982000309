import React, { useContext, useEffect, useState } from "react";

import { DependencyContext } from "../../DependencyProvider";

import "./microsoft-sign-in.scss";

const SignInComponent: React.FC = () => {
  // get the depencencies
  const authService = useContext(DependencyContext)!.authService;

  // setup the state
  const [signinUrl, setSigninUrl] = useState<string>();

  useEffect(() => {
    authService.getSigninUrl().then((value: string) => {
      setSigninUrl(value);
    });
  });

  return (
    <a href={signinUrl} title="Sign in">
      <img alt="Sign in with Microsoft" src="/images/microsoft.png" />
    </a>
  );
};

export default SignInComponent;
