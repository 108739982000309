import Header from "../header/header";
import SignInComponent from "../microsoft-sign-in/microsoft-sign-in";

import "./login.scss";

const LoginComponent: React.FC = () => {
  return (
    <div className="login">
      <Header />
      <main>
        Photo labels will be available until you close your browser. Sign in to
        save them permanently.
        <SignInComponent />
        <a href="#/app">Skip</a>
      </main>
    </div>
  );
};

export default LoginComponent;
