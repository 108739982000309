import Cookies from "universal-cookie";

export interface ICookieManager {
  get(name: string): string | undefined;
}

export class CookieManager implements ICookieManager {
  private cookies = new Cookies();

  get(name: string): string | undefined {
    return this.cookies.get(name);
  }
}
