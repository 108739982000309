import "./next.css";

import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { AppState } from "../../Store";
import { useDispatch } from "react-redux";
import { nextGalleryItem } from "../../reducers/itemsReducer";

const NextComponent: React.FC = () => {
  const dispatch = useDispatch();

  // selectors
  const items = useSelector((state: AppState) => state.items.items);
  const selectedIndex = useSelector(
    (state: AppState) => state.items.selectedIndex
  );

  // state
  const [disabled, setDisabled] = useState<boolean>(true);

  useEffect(() => {
    // Update the disabled state when props change
    setDisabled(selectedIndex === items.length - 1);
  }, [items.length, selectedIndex]);

  const handleClick = (): void => {
    dispatch(nextGalleryItem());
  };

  return (
    <button
      className="next"
      disabled={disabled}
      onClick={handleClick}
      title="Next"
    >
      <img alt="Next" src="images/next.png" />
    </button>
  );
};

export default NextComponent;
