export type CaptionPosition =
  | "TopLeft"
  | "TopMiddle"
  | "TopRight"
  | "CentreLeft"
  | "CentreMiddle"
  | "CentreRight"
  | "BottomLeft"
  | "BottomMiddle"
  | "BottomRight";

export type FontFamily =
  | "cursive"
  | "fantasy"
  | "monospace"
  | "sans-serif"
  | "serif";

export type FontType = "%" | "px";

export enum Rotation {
  None,
  Ninety,
  OneEighty,
  TwoSeventy,
}

export type SortOrder = "none" | "filename" | "dateTaken";
