interface AsyncQueueItem {
  id: number;
  task: () => Promise<void>;
}

export interface IAsyncQueue {
  clear(): void;
  enqueue(id: number, task: () => Promise<void>): void;
  prioritise(id: number): boolean;
}

export class AsyncQueue implements IAsyncQueue {
  private queue: Array<AsyncQueueItem> = [];
  private taskCount = 0;

  constructor(public threads: number = 1) {}

  // clear all items from the queue
  clear(): void {
    this.queue.length = 0;
  }

  // Add item to the queue
  enqueue(id: number, task: () => Promise<void>): void {
    // add this item to the queue
    this.queue.push({
      id,
      task,
    });

    // start a new processor (if required)
    this.processQueue();
  }

  prioritise(id: number): boolean {
    // is there a task queued for that id?
    const index = this.queue.findIndex((i) => i.id === id);

    // does it exist?
    if (index < 1) {
      return false;
    }

    // move it to the top of the queue
    const items = this.queue.splice(index, 1);
    this.queue.splice(1, 0, items[0]);

    return true;
  }

  // Process the queue asynchronously
  private async processQueue() {
    while (this.taskCount < this.threads) {
      // create the new processor
      var processor = async (): Promise<void> => {
        // get the next task to run
        let item = this.queue.shift();
        while (item) {
          try {
            // run the task
            await item.task();
          } catch (error: any) {
            // log the error and then continue
            console.log(error);
          }

          // get the next task to run
          item = this.queue.shift();
        }
      };

      // start processing
      processor().then(() => {
        // once it has finished processing, remove it from the processors
        this.taskCount--;
      });

      // increase the number of processors
      this.taskCount++;
    }
  }
}
